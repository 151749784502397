const initNavbarTransparent = () => {
  const navbar = document.getElementById('navbar-desktop');
  $(document).ready(function() {
          // Transition effect for navbar
          $(window).scroll(function() {
            // checks if window is scrolled more than 500px, adds/removes solid class
            if($(this).scrollTop() > 500) {
                $('#navbar-desktop').addClass('solid');
            } else {
                $('#navbar-desktop').removeClass('solid');
            }
          });
  });

};

export { initNavbarTransparent };
