const activeLink = () => {
  let homepage = document.getElementById("homepage");
  if (homepage) {
    $(document).ready(function () {
        $(document).on("scroll", onScroll);

        //smoothscroll
        $("a[href*='#']:not([href='#'])").on('click', function (e) {
            e.preventDefault();
            $(document).off("scroll");

            $('a').each(function () {
                $(this).removeClass('active');
            })
            $(this).addClass('active');

            var target = this.hash,
                menu = target;
            let $target = $(target);
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top+2
            }, 500, 'swing', function () {
                window.location.hash = target;
                $(document).on("scroll", onScroll);
            });
        });
    });

    function onScroll(event){
        var scrollPos = $(document).scrollTop();
        // console.log($('#menu-center div a'))
        $('.navbar-list-item').each(function () {

            var currLink = $(this);
            // console.log(currLink.attr("href").replace('/', ''))
            var refElement = $(currLink.attr("href").replace('/', ''));
            if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                $('navbar-list-item').removeClass("active");
                currLink.addClass("active");
            }
            else{
                currLink.removeClass("active");
            }
        });
    }

  }

};

export { activeLink };




